import React from "react";
import {Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';
import {RegisterStock} from '../../utils/svgs';
import {translateStatus} from '../../utils/translations'

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListOrdersPending extends pageList {
	
	constructor(props){
		super(props)

		this.apiName = 'orders';
		this.listSlug = 'orders';
		this.singleItem = 'pedido'; //Name of a single list item
		this.listTitle = 'Pedidos pendientes';
		
		this.itemLinkSlug = 'pedidos';

		// this.hasRowOptions = false;

	}
	
	componentDidMount(){
		this.requestPages();
	}


	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'store',
				name: 'Tienda',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'order_number',
				name: 'Nº Pedido',
				class: 'item-small',
				canSearch: true,
			},
			{
				slug: 'date',
				name: 'Fecha',
				class: 'item-medium',
			},
			{
				slug: 'status',
				name: 'Estado',
				class: 'item-medium',
				canSort: true,
				sortType: 'string',
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			store : page.store,
			order_number : page.order_number,
			date : new Date(page.time).toLocaleString(),
			status : translateStatus(page.status),
		};
	}

	renderDeleteIcon(row, index){
		return (
			<div onClick={()=>window.location='/remove-stock-pedido/'+row.item.id} title="Registrar pedido" className="icon-container font-right">
				<RegisterStock/>
			</div>
		);
		
	}

	
	renderAddNew(){}
	
}





export default pageListOrdersPending;




